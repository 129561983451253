/* eslint-disable prettier/prettier */
/* eslint-disable no-empty */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Chip,
  Table,
  Button,
  Divider,
  Popover,
  TableRow,
  MenuItem,
  Checkbox,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  IconButton,
  Typography,
  ListItemText,
  Autocomplete,
  TableContainer
} from '@mui/material';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
// import Upvotes from '../../components/Upvotes';
// import MatchFilter from './components/MatchFilter';
import RoleTypeTag from '../../components/RoleTypeTag';
import { useLoader } from '../../components/useLoader';
import ChatProfile from '../chat/chat-main/ChatProfile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MyPagination from '../../components/MyPagination';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import AssignMatchPopup1 from './components/AssignMatchPopup1';
import AssignMatchPopup2 from './components/AssignMatchPopup2';
import ConfirmationPopup from './components/ConfirmationPopup';
import MatchDetailsPopup from './components/MatchDetailsPopup';
import MatchDeleteDialog from './components/MatchDeleteDialog';
import { CommonStyle, adminPanel } from '../../../../utils/muiStyle';
import { FilterSearch } from '../../components/filter/FilterProperty';
import { PreviewIcon, ProfilePersonIcon } from '../../../../utils/Icons';
//import ExportButton from '../Program/components/program tabs/ExportButton';
import LinearProgressWithLabel from './components/LinearProgressWithLabel';
import { setMessageAuto } from '../../../../redux/slices/AutoMatchMessage';
import MatchesMainPageLoader from '../../../../Loader/MatchesMainPageLoader';
//import ExportPopover from '../Program/components/program tabs/ExportPopover';
import {
  getGoalProgressShowOrNot,
  GroupBasedRoleFormatter,
  roleNameFormatter,
  unixToHumanReadable
} from '../../components/common';
import { fetchMatches, matchesActions } from '../../../../redux/slices/getAllMatches/getAllMatchesSlice';
import { eventTracking, events } from '../../../../utils/amplitude';

interface MatchingFields {
  gender: boolean;
  firstGenStudent: boolean;
  ethnicity: boolean;
  disability: boolean;
  hobbies: boolean;
  major: boolean;
  university: boolean;
  industry: boolean;
  title: boolean;
  topics: boolean;
  industries: boolean;
}

interface SelectMentorsProps {
  memberList: Array<{
    displayName: string;
    role: string;
    headshot: string;
    major: boolean;
    university: boolean;
    industry: boolean;
    title: boolean;
    topics: boolean;
    industries: boolean;
    // Add other properties as needed
  }>;
  hasAnyTrueValue: boolean;
  assignIdFunction: (selectedOption: any) => void;
  setSelectValueMentor: React.Dispatch<React.SetStateAction<boolean>>;
  setMentorId: React.Dispatch<React.SetStateAction<string>>;
  setMenteeId: React.Dispatch<React.SetStateAction<string>>;
}

interface Recommendation {
  role: string;
  searchQuery: { field: string; value: string; match: string }[];
  matchedFieldsCount: number;
  mentorId: string;
  menteeId: string;
  headshot: string;
  displayName: string;
  bio: string;
}

interface DataItem {
  message: string;
  data: {
    grpId: string;
    isConfirmed: boolean;
    matchStatus: string;
    futureCalls: number;
    pastCalls: number;
    lastConnection: any;
    id: string;
    searchQuery: {
      field: string;
      value: string;
      isMatched: boolean;
    }[];
    mentee: {
      bio: {
        education: {
          university: string;
          major: string;
          graduationDate: string;
        };
        workHistory: {
          companyName: string;
          role: string;
        };
      };
      displayName: string;
      id: string;
      headshot: string;
      goalProgress: number;
    };
    mentor: {
      bio: {
        workHistory: {
          companyName: string;
          role: string;
        };
        education: {
          university: string;
          major: string;
          graduationDate: string;
        };
      };
      displayName: string;
      id: string;
      headshot: string;
    };
  };
}

const initialMatchingFields: MatchingFields = {
  gender: false,
  firstGenStudent: false,
  ethnicity: false,
  disability: false,
  hobbies: false,
  major: false,
  university: false,
  industry: false,
  title: false,
  topics: false,
  industries: false
};

/** Select member field */
const SelectMentors: React.FC<SelectMentorsProps> = ({
  memberList,
  hasAnyTrueValue,
  setMentorId,
  setMenteeId,
  assignIdFunction,
  setSelectValueMentor
}) => {
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor');
  const menteeRoleText = GroupBasedRoleFormatter('mentee', 'Mentee');

  /** Menu item design */
  const renderCustomMenuItem = (props: any, option: any, { selected }: { selected: boolean }) => {
    const renderInnerContent = () => (
      <Box gap={'8px'} display={'flex'} alignItems={'center'} width="calc(100% - 60px)" className="cursor-pointer">
        <ChatAvatarComponent
          image={option.headshot}
          firstLetter={option.displayName?.slice(0, 1)}
          width={'40px'}
          height={'40px'}
          cursor="pointer"
          type="noStatus"
        />
        <Box width="calc(100% - 64px)">
          <Box display={'flex'} gap={'8px'}>
            <Typography variant="h5" noWrap>
              {option.displayName}
            </Typography>
            <RoleTypeTag category={option.role} />
          </Box>
          <Typography noWrap>Testing bio</Typography>
        </Box>
      </Box>
    );

    return (
      <MenuItem {...props}>
        <Checkbox checked={selected} />
        <ListItemText primary={renderInnerContent()} />
      </MenuItem>
    );
  };

  return (
    <Autocomplete
      id="memberList"
      options={memberList}
      getOptionLabel={(option) =>
        `${option.displayName} - ${roleNameFormatter(option.role, mentorRoleText, menteeRoleText)}`
      }
      // renderOption={renderCustomMenuItem}
      renderInput={(params) => <TextField {...params} placeholder="Select" />}
      onChange={(event, selectedOption) => {
        if (selectedOption) {
          assignIdFunction(selectedOption);
          setSelectValueMentor(true);
        } else {
          setMentorId('');
          setMenteeId('');
          setSelectValueMentor(false);
        }
      }}
      disabled={hasAnyTrueValue}
    />
  );
};

const MatchesMainPage = () => {
  /** Common Design classes */
  const CommonClass = CommonStyle();
  const ImageNameBox = `${CommonClass.gap} ${CommonClass.displayFlex} ${CommonClass.alignItemsCenter}`;
  const params = useParams();
  const grpId = params.id || '';
  const orgId = params.orgId || '';
  const dispatch: any = useAppDispatch();
  const groupUsers = useAppSelector((state) => state.groupUsers);
  const allMatchesData = useAppSelector((state) => state?.getAllMatchesList?.data);
  const loading = useAppSelector((state) => state?.getAllMatchesList?.loading);
  const error = useAppSelector((state) => state?.getAllMatchesList?.error);
  const messageAutoMatch = useAppSelector((state) => state?.message?.message);
  const matchesCount =
    (allMatchesData &&
      Array.isArray(allMatchesData.matches) &&
      allMatchesData.matches.filter((match) => match && !match.isConfirmed).length) ||
    0;
  const [dataArray, setDataArray] = useState<DataItem[]>([]);
  console.log('allMatchesData: ', allMatchesData);

  const confirmedCount = dataArray.filter((item) => !item?.data?.isConfirmed).length;
  const [matchingFields, setMatchingFields] = useState<MatchingFields>(initialMatchingFields);
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [suggestions, setSuggestions] = useState<Recommendation[]>([]);
  const [selectedRecommendation, setSelectedRecommendation] = useState<Recommendation | null>(null);
  const [openAssignMentor, setOpenAssignMentor] = useState(false);
  const [openAssignMentorSecond, setOpenAssignMentorSecond] = useState(false);
  const [userId, setUserId] = useState<string>('');
  let [menteeId, setMenteeId] = useState('');
  let [mentorId, setMentorId] = useState('');
  const [action, setAction] = useState('');
  const [memberList, setMemberList] = useState<any[]>([]);
  const [selectValueMentor, setSelectValueMentor] = useState(false);
  const [openMatchDetails, setOpenMatchDetails] = useState(false);
  const [modelConfirmMatch, setModelConfirmMatch] = useState(false);
  const [confirmMatchId, setConfirmMatchId] = useState('');
  const [loaderFirstPopup, setLoaderFirstPopup] = useState(false);
  const [loaderFirstPopup1, setLoaderFirstPopup1] = useState(false);
  const [anchorElAssignMentor, setAnchorElAssignMentor] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElAssignMentee, setAnchorElAssignMentee] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElConfirm, setAnchorElConfirm] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElConfirmdataArray, setAnchorElConfirmdataArray] = React.useState<HTMLButtonElement | null>(null);
  const [maxMatchCountMentee, setMatchCountMentee] = useState<number | undefined>();
  const [maxMatchCountMentor, setMatchCountMentor] = useState<number | undefined>();
  const openMentee = Boolean(anchorElAssignMentee);
  const idMentee = openMentee ? 'simple-popover' : undefined;
  const open = Boolean(anchorElAssignMentor);
  const id = open ? 'simple-popover' : undefined;
  const openConfirm = Boolean(anchorElConfirm);
  const openConfirmdataArray = Boolean(anchorElConfirmdataArray);
  const idConfirm = openConfirm ? 'simple-popover' : undefined;
  const idConfirmdataArray = openConfirmdataArray ? 'simple-popover' : undefined;
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [matchDetails, setMatchDetails] = useState();
  const [matchSingle, setMatchSingle] = useState<any>();
  const [activeUserId, setActiveUserId] = useState<any>();
  let [assigneeId, setAssigneeId] = useState('');
  const [activeUserDetails, setActiveUserDetails] = useState<any>();
  const [latestEducation, setLatestEducation] = useState<any>();
  const [latestWorkHistory, setLatestWorkHistory] = useState<any>();
  const [openProfile, setOpenProfile] = useState(false);
  const [matchDetailsLoader, setMatchDetailsLoader] = useState(false);
  const [loadingUserDetails, setLoadingUserDetails] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  // const [anchorExport, setAnchorExport] = React.useState<HTMLButtonElement | null>(null);
  // const [isClicked, setIsClicked] = useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const handleopenFilter = (flag: boolean) => setOpenFilter(flag);
  const classs = adminPanel();
  const showGoalProgress = getGoalProgressShowOrNot(groupUsers.data?.showGoalProgress);
  const copiedUserDetails = {
    ...activeUserDetails,
    id: activeUserDetails?.personal_details,
    bio: { education: latestEducation, workHistory: latestWorkHistory },
    education: { updatedData: activeUserDetails?.professional_details?.education },
    workHistory: { updatedData: activeUserDetails?.professional_details?.workHistory }
    // digital_intro: activeUserDetails?.digital_intro?.videoUrl
  };

  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor'); // Mentor
  const mentorRoleTextUpperCase = mentorRoleText.toUpperCase(); // MENTOR
  const mentorRoleTextLowerCase = mentorRoleText.toLowerCase(); // mentor
  const menteeRoleText = GroupBasedRoleFormatter('mentee', 'Mentee'); // Mentee
  const menteeRoleTextUpperCase = menteeRoleText.toUpperCase(); // MENTEE
  const menteeRoleTextLowerCase = menteeRoleText.toLowerCase(); // mentee

  const handleCloseMatchDetails = () => {
    setOpenMatchDetails(false);
    // setLoaderFirstPopup(false);
  };
  const handleOpenMatchDetails = async () => {
    setMatchDetails(undefined);
    setAnchorElConfirm(null);
    setOpenMatchDetails(true);
    setMatchDetailsLoader(true);
    try {
      const response = await API.getMatchProfile({
        orgId: orgId,
        confirmMatchId: confirmMatchId
      });
      if (response?.status === 200) {
        setMatchDetailsLoader(false);
        setMatchDetails(response?.data?.data);
      }
    } catch (error) {}
  };

  const handleOpenAssignMentor = (id: string) => {
    setAction('Assign Mentor');
    setUserId(id);
    setOpenAssignMentor(true);
    setSelectedRecommendation(null);
  };

  const handelAssignMentorAction = () => {
    setAction('Assign Mentor');
    setOpenAssignMentor(true);
    setAnchorElAssignMentor(null);
  };

  const handelOpenProfileAction = async () => {
    setAnchorElAssignMentor(null);
    setAnchorElAssignMentee(null);
    try {
      setOpenProfile(false);
      const response = await API.getUserProfileInAGroup(grpId, activeUserId);
      if (response?.status === 200) {
        setActiveUserDetails(response?.data?.userDetails);
        setOpenProfile(true);
      } else {
        setOpenProfile(false);
        toast.error('Profile details failed to load');
      }
      setActiveUserId('');
    } catch (e) {
      setOpenProfile(false);
      toast.error('Profile details failed to load');
    }
  };

  const handelAssignMenteeAction = () => {
    setAction('Assign Mentee');
    setOpenAssignMentor(true);
    setAnchorElAssignMentee(null);
  };

  const handleOpenAssignMentee = (id: string) => {
    setAction('Assign Mentee');
    setSelectedRecommendation(null);
    setUserId(id);
    setOpenAssignMentor(true);
  };

  const handleCloseAssignMentor = () => {
    setOpenAssignMentor(false);
    setLoaderFirstPopup(false);
    setMatchingFields(initialMatchingFields);
    setLoaderFirstPopup1(false);
    setMentorId('');
    setMenteeId('');
    setSelectValueMentor(false);
  };

  const fetchAllMembers = async () => {
    const response = await API.getListToAssignMatch({
      orgId: orgId,
      userId: userId || activeUserId,
      grpId: grpId,
      data: {
        assigneeRole: action === 'Assign Mentor' ? 'Mentee' : 'Mentor'
      },
      isMatchingFields: false
    });
    if (response?.data?.data) {
      setMemberList(response?.data?.data);
    }
  };

  const assignIdFunction = (selectedOption: any) => {
    if (selectedOption.role === 'Mentee') {
      // setAssigneeId(selectedOption?.menteeId);
      setMentorId(userId);
      setMenteeId(selectedOption?.menteeId);
    }
    if (selectedOption.role === 'Mentor') {
      // setAssigneeId(selectedOption?.mentorId);
      setMentorId(selectedOption?.mentorId);
      setMenteeId(userId);
    }
  };

  const handleOpenFindmentor = async (event: React.FormEvent) => {
    const hasTrueValue = Object.values(matchingFields).some((value) => value === true);
    if (selectValueMentor === false && hasTrueValue === false) {
      toast.error('Please make a selection');
    }
    if (selectValueMentor === false && hasTrueValue) {
      setLoaderFirstPopup(true);
      event.preventDefault();
      const response = await API.getListToAssignMatch({
        orgId: orgId,
        userId: userId || activeUserId,
        grpId: grpId,
        data: {
          assigneeRole: action === 'Assign Mentor' ? 'Mentee' : 'Mentor',
          matchingFields
        },
        isMatchingFields: true
      });

      if (response?.status === 200) {
        setRecommendations(response.data?.data?.recommendations);
        setSuggestions(response.data?.data?.suggestions);
        setMenteeId(response.data?.data?.MenteeId);
        setMentorId(response.data?.data?.MentorId);
        setLoaderFirstPopup(false);
        setMatchingFields(initialMatchingFields);
      } else {
        setLoaderFirstPopup(false);
        setMatchingFields(initialMatchingFields);
        if (response?.status === 400) {
          toast.error('User has reached their maximum number of matches');
        }
      }

      setOpenAssignMentorSecond(true);
      setOpenAssignMentor(false);
    }
    // selecting from list
    if (selectValueMentor === true) {
      setLoaderFirstPopup(true);
      // 2nd api
      const response = await API.assignAMatch({
        orgId: orgId,
        grpId: grpId,
        data: {
          mentorId: mentorId,
          menteeId: menteeId,
          // searchQuery
          assigneeRole: action === 'Assign Mentor' ? 'Mentee' : 'Mentor'
        }
      });

      if (response?.status === 200) {
        setLoaderFirstPopup(false);
        setDataArray([...dataArray, response.data]);
        if (action === 'Assign Mentor') {
          toast.success(mentorRoleText + ' assigned successfully');
        }
        if (action === 'Assign Mentee') {
          toast.success(menteeRoleText + ' assigned successfully');
        }
        dispatch(
          matchesActions.updateMenteesMentors({
            menteeId: menteeId,
            mentorId: mentorId
          })
        );
      } else {
        setLoaderFirstPopup(false);
        toast.error('Something went wrong, please try again');
      }
      setOpenAssignMentor(false);
      setMenteeId('');
      setMentorId('');
      setSelectValueMentor(false);
    }
  };

  const handleClickOpenAutoMatch = () => {
    //   setOpen(true);
    setAction('Auto Match');
    setOpenAssignMentor(true);
  };

  const handelCloseAssineMatch = () => {
    setOpenAssignMentor(false);
    setOpenAssignMentorSecond(false);
    setLoaderFirstPopup(false);
    setLoaderFirstPopup1(false);
    setMentorId('');
    setMenteeId('');
    setSelectValueMentor(false);
  };

  const handleSubmitAssignMatch = async () => {
    setLoaderFirstPopup(true);

    // const searchQuery = selectedRecommendation?.searchQuery.map((query) => ({
    //   field: query.field,
    //   value: query.value,
    //   match: query.match
    // }));
    if (selectedRecommendation) {
      const response = await API.assignAMatch({
        orgId: orgId,
        grpId: grpId,
        data: {
          mentorId: action === 'Assign Mentor' ? selectedRecommendation?.mentorId : mentorId || activeUserId,
          menteeId: action === 'Assign Mentor' ? menteeId || activeUserId : selectedRecommendation?.menteeId,
          // searchQuery
          assigneeRole: action === 'Assign Mentor' ? 'Mentee' : 'Mentor'
        }
      });

      if (response?.status === 200) {
        setLoaderFirstPopup(false);
        setDataArray([...dataArray, response.data]);
        if (action === 'Assign Mentor') {
          toast.success(mentorRoleText + ' assigned successfully');
        }
        if (action === 'Assign Mentee') {
          toast.success(menteeRoleText + ' assigned successfully');
        }
        dispatch(
          matchesActions.updateMenteesMentors({
            menteeId: userId,
            mentorId: selectedRecommendation?.mentorId || ''
          })
        );
      } else {
        toast.error('Something went wrong, please try again');
      }
    }

    setOpenAssignMentor(false);
    setOpenAssignMentorSecond(false);
    setLoaderFirstPopup(false);
    setMenteeId('');
    setMentorId('');
  };

  const handleConfirmMatch = async (id: string) => {
    setAnchorElConfirm(null);
    setConfirmMatchId(id);
    setModelConfirmMatch(true);
  };

  const handleOpenMatchPopover = async (id: string, event: React.MouseEvent<HTMLButtonElement>) => {
    const match = allMatchesData?.matches.find((ele) => ele.id === id);
    if (match) {
      setMatchSingle(match);
    }
    setConfirmMatchId(id);
    setAnchorElConfirm(event.currentTarget);
    setOpenProfile(false);
  };

  const handleCloseConfirmMatch = async () => {
    setModelConfirmMatch(false);
    setLoaderFirstPopup(false);
  };

  const handleConfirmAll = async () => {
    setLoaderFirstPopup1(true);
    try {
      const response = await API.confirmAllMatches({
        orgId: orgId,
        grpId: grpId
      });

      if (response?.status === 200) {
        const updatedDataArray = dataArray.map((item) => {
          item.data.isConfirmed = true;
          return item;
        });
        eventTracking(events.matchByAdmin, {});
        setDataArray(updatedDataArray);

        dispatch(matchesActions.updateConfirmStatusAll());
        setLoaderFirstPopup1(false);
        setLoaderFirstPopup(false);
        toast.success('All matches are confirmed successfully');
        setAnchorElConfirm(null);
      } else {
        setLoaderFirstPopup1(false);
        setLoaderFirstPopup(false);
        toast.error('Somthing went wrong');
      }
    } catch (error) {}
    setModelConfirmMatch(false);
  };

  const handelSubmitConfirmMatch = async () => {
    setLoaderFirstPopup(true);
    try {
      const response = await API.confirmMatch({
        orgId: orgId,
        grpId: grpId,
        data: {
          id: confirmMatchId
        }
      });

      if (response?.status === 200) {
        eventTracking(events.matchByAdmin, {});
        const updatedDataArray = dataArray.map((item) => {
          if (item.data.id === response?.data?.data?.id) {
            item.data.isConfirmed = true;
          }
          return item;
        });

        setDataArray(updatedDataArray);
        dispatch(matchesActions.updateConfirmStatus(response?.data?.data?.id));
        setLoaderFirstPopup(false);
        toast.success('Match confirmed successfully');
        setAnchorElConfirm(null);
      } else {
        toast.error('Something went wrong, please try again');
        setLoaderFirstPopup(false);
      }
    } catch (error) {}
    setModelConfirmMatch(false);
  };

  const handleAssignMentorPopover = async (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorElAssignMentor(event.currentTarget);
    setActiveUserId(id);
    // setSelectedUserId(id);
    setOpenProfile(false);
  };

  const handelOpenProfile = (id: string) => {
    setSelectedUserId(id);
    // setOpenProfile(true);
    setAnchorElAssignMentor(null);
    setAnchorElAssignMentee(null);
  };

  const handleCloseAssignMentorPopover = () => {
    setAnchorElAssignMentor(null);
  };

  const getUserProfileDetails = async () => {
    if (selectedUserId) {
      try {
        setOpenProfile(false);
        const response = await API.getUserProfileInAGroup(grpId, selectedUserId);
        if (response?.status === 200) {
          setActiveUserDetails(response?.data?.userDetails);
          setOpenProfile(true);
        } else {
          setOpenProfile(false);
          toast.error('Profile details failed to load');
        }
        setSelectedUserId('');
      } catch (e) {
        setOpenProfile(false);
        toast.error('Profile details failed to load');
      }
    }
  };

  const handleAssignMenteePopover = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorElAssignMentee(event.currentTarget);
    setActiveUserId(id);
    setOpenProfile(false);
  };

  const handleCloseAssignMenteePopover = () => {
    setAnchorElAssignMentee(null);
  };

  const handleConfirmPopoverdataArray = (id: string, event: React.MouseEvent<HTMLButtonElement>) => {
    const match = dataArray.find((ele) => ele?.data?.id === id);
    if (match) {
      setMatchSingle(match);
    }
    setConfirmMatchId(id);

    setAnchorElConfirmdataArray(event.currentTarget);
    setOpenProfile(false);
  };

  const handleCloseConfirmPopoverdataArray = () => {
    setAnchorElConfirmdataArray(null);
  };

  const handleCloseConfirmPopover = () => {
    setAnchorElConfirm(null);
  };

  const getMaxMatches = async () => {
    try {
      const response = await API.getAllMatchesSetting({
        orgId: orgId,
        grpId: grpId
      });

      if (response?.status === 200) {
        const { maxMatchesPerMentor, maxMatchesPerMentee } = response.data.data.matchesSettings;
        setMatchCountMentee(maxMatchesPerMentee);
        setMatchCountMentor(maxMatchesPerMentor);
      } else {
      }
    } catch (error) {
      toast.error('Matches failed to load');
    }
  };

  const handleOpendelete = () => {
    setOpenDelete(true);
    setAnchorElConfirm(null);
  };

  const handeleDeleteMatch = async () => {
    setDeleteLoader(true);
    try {
      const response = await API.removeMatch({
        orgId: orgId,
        grpId: grpId,
        confirmMatchId: confirmMatchId
      });

      if (response?.status === 200) {
        setDeleteLoader(false);
        setOpenDelete(false);
        const updatedDataArray = dataArray.map((item) => {
          if (item.data.id === confirmMatchId) {
            item.data.matchStatus = 'Inactive';
          }
          return item;
        });
        setDataArray(updatedDataArray);
        dispatch(matchesActions.endMatch(confirmMatchId));
        toast.success('Match ended successfully.');
      }
    } catch (error) {
      toast.error('Match failed to end');
    }
  };

  useEffect(() => {
    if (selectedUserId) {
      getUserProfileDetails();
    }
  }, [selectedUserId]);

  useEffect(() => {
    setLatestEducation(
      activeUserDetails?.professional_details?.education?.sort(
        (a: any, b: any) => b?.graduation_date - a?.graduation_date
      )[0]
    );
    setLatestWorkHistory(
      activeUserDetails?.professional_details?.workHistory
        ?.filter((a: any) => a.currentlyWorking === true)
        ?.sort((a: any, b: any) => b.start_date - a.start_date)[0]
    );
  }, [activeUserDetails]);

  useEffect(() => {
    getMaxMatches();
  }, []);

  useEffect(() => {
    fetchAllMembers();
  }, [action]);

  const handleSubmitAutoMatch = async () => {
    setLoaderFirstPopup(true);
    const response = await API.autoMatch({
      orgId: orgId,
      grpId: grpId,
      data: {
        maxMatchesPerMentor: maxMatchCountMentor,
        maxMatchesPerMentee: maxMatchCountMentee,
        matchingFields
      }
    });

    if (response?.status === 200) {
      setOpenAssignMentor(false);
      setLoaderFirstPopup(false);
      dispatch(setMessageAuto(response.data.message));
      toast.success('Auto match completed successfully');
      setMatchingFields(initialMatchingFields);
      if (response.data === 'Auto-matching completed') {
        dispatch(fetchMatches({ orgId, groupId: grpId }));
      }
    } else {
      toast.error('Somthing went wrong');
      setLoaderFirstPopup(false);
    }
  };

  window.addEventListener('beforeunload', () => {
    dispatch(setMessageAuto(''));
  });

  useEffect(() => {
    dispatch(setMessageAuto(''));
  }, []);

  useEffect(() => {
    if (orgId && grpId) {
      dispatch(fetchMatches({ orgId, groupId: grpId }));
    }
  }, [orgId, grpId, dispatch]);

  const showLoader = useLoader(loading, 1000);

  /** search */

  const [searchValue, setSearchValue] = useState('');

  const filterData = (data: any[], searchValue: string) => {
    if (Array.isArray(data)) {
      return data.filter((item) => {
        // filter by mentee or mentor name
        return (
          item?.mentee?.displayName.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.mentor?.displayName.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }
    return [];
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const getMatchesList = () => {
    // console.log(allMatchesData);
    // const filteredData = filterData(allMatchesData?.matches || [], searchValue);
    // if (error) {
    //   return <Box>Error occurred while fetching matches.</Box>;
    // }

    return (
      <>
        {Array.isArray(allMatchesData?.matches) &&
          allMatchesData?.matches?.map((ele, index) => (
            <TableRow key={ele.id}>
              <TableCell>
                <Box className={ImageNameBox}>
                  <Box>
                    <ChatAvatarComponent
                      image={ele?.mentee?.headshot}
                      type="noStatus"
                      firstLetter={ele?.mentee?.displayName.charAt(0)}
                      width="40px"
                      height="40px"
                      onClickOnUserIcon={() => handelOpenProfile(ele?.mentee?.menteeId)}
                    />
                  </Box>
                  <Box className="cursor-pointer" onClick={() => handelOpenProfile(_.get(ele, 'mentee.menteeId'))}>
                    <Typography variant="h5">
                      {ele?.mentee?.displayName.length > 15
                        ? `${ele?.mentee?.displayName.substring(0, 15)}...`
                        : ele?.mentee?.displayName}
                    </Typography>
                    {ele?.mentee?.bio?.workHistory?.role || ele?.mentee?.bio?.education?.major ? (
                      <Typography>
                        {(ele?.mentee?.bio?.workHistory?.role || ele?.mentee?.bio?.education?.major || '').length > 15
                          ? `${(
                              ele?.mentee?.bio?.workHistory?.role ||
                              ele?.mentee?.bio?.education?.major ||
                              ''
                            ).substring(0, 15)}...`
                          : ele?.mentee?.bio?.workHistory?.role || ele?.mentee?.bio?.education?.major}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Box className={ImageNameBox}>
                  <Box>
                    <ChatAvatarComponent
                      image={ele?.mentor?.headshot}
                      type="noStatus"
                      firstLetter={ele?.mentor?.displayName.charAt(0)}
                      width="40px"
                      height="40px"
                      cursor={'pointer'}
                      onClickOnUserIcon={() => handelOpenProfile(ele?.mentor?.mentorId)}
                    />
                  </Box>
                  <Box className="cursor-pointer" onClick={() => handelOpenProfile(_.get(ele, 'mentor.mentorId'))}>
                    <Typography variant="h5">
                      {ele?.mentor?.displayName.length > 15
                        ? `${ele?.mentor?.displayName.substring(0, 20)}...`
                        : ele?.mentor?.displayName}
                    </Typography>
                    {ele?.mentor?.bio?.workHistory?.role || ele?.mentor?.bio?.education?.major ? (
                      <Typography>
                        {(ele?.mentor?.bio?.workHistory?.role || ele?.mentor?.bio?.education?.major || '').length > 15
                          ? `${(
                              ele?.mentor?.bio?.workHistory?.role ||
                              ele?.mentor?.bio?.education?.major ||
                              ''
                            ).substring(0, 15)}...`
                          : ele?.mentor?.bio?.workHistory?.role || ele?.mentor?.bio?.education?.major}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                {ele?.isConfirmed ? (
                  <Chip
                    color="success"
                    variant="outlined"
                    size="small"
                    sx={{
                      fontSize: '12px',
                      fontWeight: '600',
                      borderRadius: '5px'
                    }}
                    label={ele?.matchStatus === 'Inactive' ? 'Ended' : 'Matched'}
                  />
                ) : (
                  <Button
                    color="info"
                    size="medium"
                    variant="contained"
                    style={{
                      padding: '8px 10px',
                      fontWeight: '400'
                    }}
                    onClick={() => ele && handleConfirmMatch(_.get(ele, 'id'))}
                  >
                    Confirm Match
                  </Button>
                )}
              </TableCell>
              {showGoalProgress && (
                <TableCell>
                  <LinearProgressWithLabel value={ele?.mentee?.goalProgress ?? ele?.mentee?.goalProgress} />
                </TableCell>
              )}
              {/* <TableCell>
                <Upvotes likes={86} />
              </TableCell> */}
              <TableCell>
                <Typography>{ele?.pastCalls}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{ele?.futureCalls}</Typography>
              </TableCell>
              {/* Last Connection */}
              <TableCell>
                <Typography>{ele?.lastConnection && unixToHumanReadable(ele?.lastConnection)}</Typography>
              </TableCell>
              <TableCell colSpan={2}>
                <IconButton onClick={(event) => handleOpenMatchPopover(_.get(ele, 'id'), event)}>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

        {/* Popover */}
        <Popover
          id={idConfirm}
          open={openConfirm}
          anchorEl={anchorElConfirm}
          onClose={handleCloseConfirmPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {matchSingle?.matchStatus === 'Inactive' && (
            <Box className="PopoverListStyle">
              <ProfilePersonIcon />
              <Typography color="primary">Ended</Typography>
            </Box>
          )}
          {matchSingle?.isConfirmed && matchSingle?.matchStatus === 'Active' && (
            <Box className="PopoverListStyle" onClick={handleOpendelete}>
              <ProfilePersonIcon />
              <Typography color="primary">Remove match</Typography>
            </Box>
          )}
          {matchSingle?.isConfirmed === false && (
            <Box className="PopoverListStyle" onClick={() => handleConfirmMatch(_.get(matchSingle, 'id'))}>
              <ProfilePersonIcon />
              <Typography color="primary">Confirm Match</Typography>
            </Box>
          )}
          <Box className="PopoverListStyle" onClick={handleOpenMatchDetails}>
            <PreviewIcon />
            <Typography color="primary">View match details</Typography>
          </Box>
        </Popover>

        {dataArray &&
          dataArray.map((item) => (
            <TableRow>
              <TableCell>
                <Box className={ImageNameBox}>
                  <Box>
                    <ChatAvatarComponent
                      image={item?.data?.mentee?.headshot}
                      type="noStatus"
                      firstLetter={item.data?.mentee?.displayName.charAt(0)}
                      width="40px"
                      height="40px"
                      cursor={'pointer'}
                      onClickOnUserIcon={() => handelOpenProfile(_.get(item, 'data.mentee.id'))}
                    />
                  </Box>
                  <Box className="cursor-pointer" onClick={() => handelOpenProfile(_.get(item, 'data.mentee.id'))}>
                    <Typography variant="h5">{item.data?.mentee?.displayName}</Typography>
                    <Typography>
                      {(item.data?.mentee?.bio?.workHistory?.role || item.data?.mentee?.bio?.education?.major || '')
                        .length > 15
                        ? `${(
                            item.data?.mentee?.bio?.workHistory?.role ||
                            item.data?.mentee?.bio?.education?.major ||
                            ''
                          ).substring(0, 15)}...`
                        : item.data?.mentee?.bio?.workHistory?.role || item.data?.mentee?.bio?.education?.major}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Box className={ImageNameBox}>
                  <Box>
                    <ChatAvatarComponent
                      image={item.data?.mentor?.headshot}
                      type="noStatus"
                      firstLetter={item.data?.mentor?.displayName.charAt(0)}
                      width="40px"
                      height="40px"
                      cursor={'pointer'}
                      onClickOnUserIcon={() => handelOpenProfile(_.get(item, 'data.mentor.id'))}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h5" onClick={() => handelOpenProfile(_.get(item, 'data.mentor.id'))}>
                      {item.data?.mentor?.displayName}
                    </Typography>
                    <Typography>
                      {(item.data?.mentor?.bio?.workHistory?.role || item.data?.mentor?.bio?.education?.major || '')
                        .length > 15
                        ? `${(
                            item.data?.mentor?.bio?.workHistory?.role ||
                            item.data?.mentor?.bio?.education?.major ||
                            ''
                          ).substring(0, 15)}...`
                        : item.data?.mentor?.bio?.workHistory?.role || item.data?.mentor?.bio?.education?.major}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                {item.data?.isConfirmed ? (
                  <Chip
                    color="success"
                    variant="outlined"
                    size="small"
                    sx={{
                      fontSize: '12px',
                      fontWeight: '600',
                      borderRadius: '5px'
                    }}
                    label={item.data?.matchStatus === 'Inactive' ? 'Ended' : 'Matched'}
                  />
                ) : (
                  <Button
                    color="info"
                    size="medium"
                    variant="contained"
                    style={{
                      padding: '8px 10px',
                      fontWeight: '400'
                    }}
                    onClick={() => item?.data && handleConfirmMatch(_.get(item?.data, 'id'))}
                  >
                    Confirm Match
                  </Button>
                )}
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell sx={{ textAlign: 'center' }}>
                <IconButton onClick={(event) => handleConfirmPopoverdataArray(_.get(item?.data, 'id'), event)}>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

        {/* Popover */}
        <Popover
          id={idConfirmdataArray}
          open={openConfirmdataArray}
          anchorEl={anchorElConfirmdataArray}
          onClose={handleCloseConfirmPopoverdataArray}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {matchSingle?.data?.matchStatus === 'Inactive' && (
            <Box className="PopoverListStyle" onClick={handelAssignMentorAction}>
              <ProfilePersonIcon />
              <Typography color="primary">Ended</Typography>
            </Box>
          )}
          {matchSingle?.data?.isConfirmed && matchSingle?.data?.matchStatus === 'Active' && (
            <Box className="PopoverListStyle" onClick={handleOpendelete}>
              <ProfilePersonIcon />
              <Typography color="primary">Remove match</Typography>
            </Box>
          )}
          {matchSingle?.data?.isConfirmed === false && (
            <Box className="PopoverListStyle" onClick={() => handleConfirmMatch(_.get(matchSingle?.data, 'id'))}>
              <ProfilePersonIcon />
              <Typography color="primary">Confirm Match</Typography>
            </Box>
          )}
          <Box className="PopoverListStyle" onClick={handleOpenMatchDetails}>
            <PreviewIcon />
            <Typography color="primary">View match details</Typography>
          </Box>
        </Popover>

        {Array.isArray(allMatchesData?.mentees) &&
          allMatchesData?.mentees?.map((ele, index) => (
            <TableRow key={ele.id}>
              <TableCell>
                <Box className={ImageNameBox}>
                  <Box>
                    <ChatAvatarComponent
                      image={ele?.headshot}
                      type="noStatus"
                      firstLetter={ele?.displayName.charAt(0)}
                      width="40px"
                      height="40px"
                      cursor={'pointer'}
                      onClickOnUserIcon={() => handelOpenProfile(_.get(ele, 'id'))}
                    />
                  </Box>
                  <Box className="cursor-pointer" onClick={() => handelOpenProfile(_.get(ele, 'id'))}>
                    <Typography variant="h5">
                      {/* {ele?.displayName} */}
                      {ele?.displayName.length > 15 ? `${ele?.displayName.substring(0, 15)}...` : ele?.displayName}
                    </Typography>
                    {ele?.bio?.workHistory?.role || ele?.bio?.education?.major ? (
                      <Typography>
                        {(ele?.bio?.workHistory?.role || ele?.bio?.education?.major || '').length > 15
                          ? `${(ele?.bio?.workHistory?.role || ele?.bio?.education?.major || '').substring(0, 15)}...`
                          : ele?.bio?.workHistory?.role || ele?.bio?.education?.major}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Button
                  color="info"
                  size="medium"
                  variant="outlined"
                  sx={{
                    padding: '8px 16px',
                    fontWeight: '400'
                  }}
                  onClick={() => {
                    handleOpenAssignMentor(_.get(ele, 'id'));
                  }}
                >
                  Assign a {mentorRoleTextLowerCase}
                </Button>
              </TableCell>
              {/* Goal Progress */}
              {showGoalProgress && <TableCell />}
              {/* Last Connection */}
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell sx={{ textAlign: 'center' }}>
                <IconButton onClick={(event) => handleAssignMentorPopover(event, _.get(ele, 'id'))}>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

        {/* Popover */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorElAssignMentor}
          onClose={handleCloseAssignMentorPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box className="PopoverListStyle" onClick={handelAssignMentorAction}>
            <ProfilePersonIcon />
            <Typography color="primary">Assign a {mentorRoleTextLowerCase}</Typography>
          </Box>
          <Box className="PopoverListStyle" onClick={handelOpenProfileAction}>
            <PreviewIcon />
            <Typography color="primary">View member details</Typography>
          </Box>
        </Popover>

        {Array.isArray(allMatchesData?.mentors) &&
          allMatchesData?.mentors?.map((ele: any, index) => (
            <TableRow key={index}>
              <TableCell>
                <Button
                  color="info"
                  size="medium"
                  variant="outlined"
                  sx={{
                    padding: '8px 16px',
                    fontWeight: '400'
                  }}
                  onClick={() => {
                    handleOpenAssignMentee(_.get(ele, 'id'));
                  }}
                >
                  Assign a {menteeRoleTextLowerCase}
                </Button>
              </TableCell>
              <TableCell>
                <Box className={ImageNameBox}>
                  <Box>
                    <ChatAvatarComponent
                      image={ele?.headshot}
                      type="noStatus"
                      firstLetter={ele?.displayName.charAt(0)}
                      width="40px"
                      height="40px"
                      cursor={'pointer'}
                      onClickOnUserIcon={() => handelOpenProfile(_.get(ele, 'id'))}
                    />
                  </Box>
                  <Box className="cursor-pointer" onClick={() => handelOpenProfile(_.get(ele, 'id'))}>
                    <Typography variant="h5">
                      {ele?.displayName.length > 15 ? `${ele?.displayName.substring(0, 15)}...` : ele?.displayName}{' '}
                    </Typography>
                    {ele?.bio?.workHistory?.role || ele?.bio?.education?.major ? (
                      <Typography>
                        {(ele?.bio?.workHistory?.role || ele?.bio?.education?.major || '').length > 15
                          ? `${(ele?.bio?.workHistory?.role || ele?.bio?.education?.major || '').substring(0, 15)}...`
                          : ele?.bio?.workHistory?.role || ele?.bio?.education?.major}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              </TableCell>
              {/* Goal Progress */}
              {showGoalProgress && <TableCell />}
              {/* Last Connection */}
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell sx={{ textAlign: 'center' }}>
                <IconButton onClick={(event) => handleAssignMenteePopover(event, _.get(ele, 'id'))}>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

        {/* Popover */}
        <Popover
          id={idMentee}
          open={openMentee}
          anchorEl={anchorElAssignMentee}
          onClose={handleCloseAssignMenteePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box className="PopoverListStyle" onClick={handelAssignMenteeAction}>
            <ProfilePersonIcon />
            <Typography color="primary">Assign a {menteeRoleTextLowerCase}</Typography>
          </Box>
          <Box className="PopoverListStyle" onClick={handelOpenProfileAction}>
            <PreviewIcon />
            <Typography color="primary">View member details</Typography>
          </Box>
        </Popover>
      </>
    );
  };

  const member = memberList;
  const hasAnyTrueValue = Object.values(matchingFields).some((value) => value === true);

  const [page, setPage] = useState(1);
  const itemsPerPage = 25;
  const totalItems = 10 * itemsPerPage; // 10 pages * 25 items per page
  const totalPages = 10; // Total number of pages

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  /** Export Button Popover */
  // const handleClickExport = (event: any) => {
  //   setAnchorExport(event.currentTarget);
  //   setIsClicked(true);
  // };

  // const handleCloseExport = () => {
  //   setAnchorExport(null);
  //   setIsClicked(false);
  // };

  // const handleClickDownloadPdf = (event: any) => {
  //   console.log("DownloadPdf")
  // };

  // const handleClickExportExcel = (event: any) => {
  //   console.log("DownloadPdf")
  // };

  // const handleClickExportPlan = (event: any) => {
  //   console.log("ExportPlan")
  // };

  // const openExport = Boolean(anchorExport);

  return (
    <>
      {showLoader ? (
        <MatchesMainPageLoader />
      ) : (
        <Box className={classs.mainBody}>
          <Grid container height={'100%'}>
            <Grid item xs height={'100%'}>
              {allMatchesData && allMatchesData && (
                <TableContainer sx={{ height: '100%', backgroundColor: '#FFFFFF' }}>
                  <Box px={2} display="flex" alignItems="center" bgcolor={'#FFFFFF'} justifyContent="space-between">
                    <Box display="flex" alignItems="center" columnGap="50px">
                      <Typography variant="h5" py={2}>
                        Matches List
                      </Typography>
                      {/* Search Bar */}
                      {/* <FilterSearch
                        width="290px"
                        value={searchValue}
                        onChange={handleSearchChange}
                        userTypeDate={{ placeholderText: 'Search Mentee / Mentor' }}
                      /> */}
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={'10px'}>
                      <Button
                        onClick={handleClickOpenAutoMatch}
                        variant="outlined"
                        color="info"
                        size="small"
                        sx={{
                          padding: '8px 10px'
                        }}
                        disabled={messageAutoMatch ? true : false}
                      >
                        Auto Match
                      </Button>
                      {/* Export btn */}
                      {/* <ExportButton onClick={handleClickExport} isClicked={isClicked} />
                      <ExportPopover
                        open={openExport}
                        anchorEl={anchorExport}
                        handleDownloadPdf={handleClickDownloadPdf}
                        handleExportExcel={handleClickExport}
                        handleExportPlan={handleClickExportPlan}
                        onClose={handleCloseExport}
                      /> */}
                    </Box>
                  </Box>
                  <Divider sx={{ opacity: 0.1, border: '.5px' }} />
                  <Table className="tableBody" width="100%">
                    <TableHead>
                      <TableRow>
                        <TableCell>{menteeRoleTextUpperCase}</TableCell>
                        <TableCell>{mentorRoleTextUpperCase}</TableCell>
                        <TableCell>STATUS</TableCell>
                        {showGoalProgress && <TableCell>GOAL PROGRESS</TableCell>}
                        <TableCell>PAST MEETINGS</TableCell>
                        <TableCell>FUTURE MEETINGS</TableCell>
                        <TableCell>LAST CONNECTION</TableCell>
                        {/* <TableCell>UPVOTES</TableCell> */}
                        {/* <TableCell>TOTAL CONNECTION</TableCell> */}
                        {/* <TableCell>LAST CONNECTION</TableCell> */}
                        <TableCell>ACTION</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {typeof allMatchesData.matches === 'string' &&
                      typeof allMatchesData.mentees === 'string' &&
                      typeof allMatchesData.mentors === 'string' ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Typography textAlign="center">No members are available to match yet</Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        getMatchesList()
                      )}
                    </TableBody>
                  </Table>
                  {/* Pagination */}
                  {/* <Box display={'flex'} justifyContent={'center'} className='my-20'>
                    <MyPagination
                      counts={[{ count: totalPages }]} 
                      currentPage={page} 
                      onPageChange={handlePageChange} 
                      allTotalObjects={totalItems} 
                      pageSize={itemsPerPage} 
                    />
                  </Box> */}
                </TableContainer>
              )}
            </Grid>
            {openProfile && activeUserDetails && (
              <Grid item width="350px" height={'100%'}>
                <ChatProfile
                  workHistory={copiedUserDetails?.workHistory}
                  educationDetails={copiedUserDetails?.education}
                  chatProfile={copiedUserDetails}
                  onCloseChatProfile={() => {
                    setOpenProfile(false);
                  }}
                  parentComponent="sideSmallProfile"
                  // loading={true}
                />
              </Grid>
            )}
          </Grid>
          {/* Assign a mentor first Popup */}
          <AssignMatchPopup1
            handleCloseAssignMentor={handleCloseAssignMentor}
            openAssignMentor={openAssignMentor}
            action={action}
            handleOpenFindmentor={handleOpenFindmentor}
            matchingFields={matchingFields}
            setMatchingFields={setMatchingFields}
            handleSubmitAutoMatch={handleSubmitAutoMatch}
            maxMatchCountMentee={maxMatchCountMentee}
            maxMatchCountMentor={maxMatchCountMentor}
            setMatchCountMentee={setMatchCountMentee}
            setMatchCountMentor={setMatchCountMentor}
            loaderFirstPopup={loaderFirstPopup}
            selectMentors={
              <SelectMentors
                memberList={member}
                hasAnyTrueValue={hasAnyTrueValue}
                assignIdFunction={assignIdFunction}
                setSelectValueMentor={setSelectValueMentor}
                setMentorId={setMentorId}
                setMenteeId={setMenteeId}
              />
            }
            selectValueMentor={selectValueMentor}
          />

          {/* Assign a mentor second popup */}
          <AssignMatchPopup2
            openAssignMentorSecond={openAssignMentorSecond}
            handelCloseAssineMatch={handelCloseAssineMatch}
            action={action}
            recommendations={recommendations}
            suggestions={suggestions}
            setSelectedRecommendation={setSelectedRecommendation}
            handleSubmitAssignMatch={handleSubmitAssignMatch}
            loaderFirstPopup={loaderFirstPopup}
          />

          {/* Matches confirmation popup code */}
          <ConfirmationPopup
            matchesCount={matchesCount}
            confirmedCount={confirmedCount}
            modelConfirmMatch={modelConfirmMatch}
            handleCloseConfirmMatch={handleCloseConfirmMatch}
            handelSubmitConfirmMatch={handelSubmitConfirmMatch}
            handleConfirmAll={handleConfirmAll}
            loaderFirstPopup={loaderFirstPopup}
            loaderFirstPopup1={loaderFirstPopup1}
          />

          {/* Match details popup */}
          <MatchDetailsPopup
            openMatchDetails={openMatchDetails}
            handleCloseMatchDetails={handleCloseMatchDetails}
            matchDetails={matchDetails}
            matchDetailsLoader={matchDetailsLoader}
          />

          {/* Match delete popup */}
          <MatchDeleteDialog
            openDelete={openDelete}
            setOpenDelete={setOpenDelete}
            deleteLoader={deleteLoader}
            handeleDeleteMatch={handeleDeleteMatch}
          />

          {/* MatchFilter */}
          {/* <MatchFilter
            loading={showLoader}
            openFilter={openFilter}
            handleopenFilter={handleopenFilter}
          /> */}
        </Box>
      )}
    </>
  );
};

export default MatchesMainPage;
