import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import MyTooltip from '../../../components/MyTooltip';
import MySkeleton from '../../../../../components/Skeleton';
// import { OrgBasedCurrencySymbol } from './OrgBasedCurrencySymbol';

function ProgramRoi({
  programRoi,
  programRoiLoading,
  currencySymbol
}: {
  programRoi: any;
  programRoiLoading: any;
  currencySymbol: any;
}) {
  const formattedProgramRoi = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 0
  }).format(programRoi);

  /** Currency symbol with value */
  const getValue = (symbol: any, value: any) => (
    <Typography variant="h1" fontSize="60px" noWrap>
      <sup className='fs-30 top--25'>{symbol}</sup>
      {value}
    </Typography>
  );

  return (
    <Box className="CardBox" p={2} height={'100%'}>
      <Box mb={1}>
        <Stack marginBottom='6px' direction="row">
          <Typography variant="h6">Program ROI</Typography>
          <MyTooltip
            gapleft={0.5}
            iconColor="#152536"
            title="The value of learning that members in your program have achieved across all learning features"
          />
        </Stack>
        {/* <Typography variant="body2" color="#808080">
          Last 30 days
        </Typography> */}
      </Box>
      <Box height="178px" display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="8px">
        {programRoiLoading
          ? <MySkeleton variant='rounded' height={70} width={130} />
          : formattedProgramRoi
            ? getValue(currencySymbol, formattedProgramRoi)
            : getValue(currencySymbol, 0)
        }

        {/* <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <img src={frequency} alt="frequency" width={'100%'} />
        </Box> */}
      </Box>
      {/* <OrgBasedCurrencySymbol programRoi={programRoi} programRoiLoading={programRoiLoading}/> */}
    </Box>
  );
}

export default ProgramRoi;
